body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --dark: #242423;
  --wasabi-light-1: #f0eee8;
  --wasabi-light-1-rgb: 240, 238, 232;
  --wasabi-light-2: #e7dcd2;
  --wasabi-light-2-rgb: 231, 220, 210;
  --wasabi-medium: #d7cbbe;
  --wasabi-medium-rgb: 243, 211, 176;
  --wasabi-dark-1: #c3b09f;
  --wasabi-dark-1-rgb: 248, 233, 230;
  --wasabi-accent-2: #3a4733;
  --wasabi-accent-1: #C0D7D8;
}

html {
box-sizing: border-box;

}

*, *:before, *:after {
box-sizing: inherit;
}

@font-face {
  font-family: season;
  src: url('https://thepianogarden.com.sg/assets/fonts/season.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}  
