
html, body {
min-height: 100vh;
}

body {
  background-color: var(--wasabi-accent-1);
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  color: var(--dark);
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

.logo {
  font-family: season, 'Cinzel', serif;
  font-weight: 400;
}

h1,
h2 {
  margin: 0;
  line-height: 1;
  font-family: 'Cinzel', serif;
  font-weight: 400;

  @media all and (max-width: 400px) {
    line-height: 1;
}
}

p {
  margin: 0;
}

.stage {
position: relative;
  background: white;
visibility: hidden;
}

.header {
  position: fixed;
  left: 40px;
  top: 24px;
  z-index: 100;
  display: flex;

  font-family: season, 'Cinzel', serif;
  
  @media all and (max-width: 768px) {
      display: block;
      left: 24px;
  }
}

.logo {
  font-size: 27px;
  letter-spacing: -1px;
}

.nav-btn,
.nav-btn__svg {
  width: 56px;
  height: 30px;
}

.nav-btn {
  display: block;
  margin: -2px 0 0 56px;
  
  @media all and (max-width: 768px) {
      margin: 18px 0 0 -6px;
  }
}

.nav-btn__svg {
  pointer-events: none;
}

.intro,
.footer {
  height: 70vh;
}

.intro {
  position: relative;
  padding: 5vw;
  background: #C0D7D8;
  overflow: hidden;
}

.intro__content {
  position: absolute;
  right: 8%;
  bottom: 15%;
  z-index: 3;
  
  @media all and (max-width: 768px) {
      right: auto;
  }
}

.intro__title {
  font-size: 7vw;
  overflow: hidden;
  letter-spacing: 0.1vw;
  padding-right: 2.3vw;

  @media all and (max-width: 768px) {
      margin-bottom: 5vh;
  }
}

.intro__img {
  position: absolute;
  width: 35%;
  max-width: 390px;
  height: auto;
  
  @media all and (max-width: 768px) {
      width: 75vw;
  }
}

.intro__img--1 {
  z-index: 2;
  left: 10%;
  bottom: 20%;
  opacity: 0.4;
  
  @media all and (max-width: 768px) {
     
      left: 15%;
      bottom: 37vh;
      height: 25vh;
      width: 25vh;
  }
}

.intro__img--2 {
  z-index: 1;
  left: 25%;
  bottom: 27%;
  opacity: 0.4;
  
  @media all and (max-width: 768px) {
      
      left: 20%;
      bottom: 35vh;
      height: 25vh;
      width: 25vh;
  }
}

.intro__txt {
  max-width: 35vw;
  margin-left: 43vw;
  
  @media all and (max-width: 768px) {
      max-width: 80vw;
      margin-left: 5vw;
  }
}

.slide {
  display: flex;
  align-items: stretch;
  height: 100vh;
  overflow: hidden;
  
  &:nth-of-type(even) {
      background: #C4CDC4;
  }
  
  @media all and (max-width: 768px) {
      display: block;
      position: relative;
  }
}

.col {
  flex-basis: 50%;
  
  @media all and (max-width: 768px) {
      display: block;
      width: 100%;
      height: 100vh;
  }
}

.col--1 {
  position: relative;
  z-index: 1;
  
   @media all and (max-width: 768px) {
      position: relative;
      z-index: 1;
  }
}

.col--2 {
  position: relative;
  overflow: hidden;
  
  @media all and (max-width: 768px) {
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
  }
}

.col__content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  height: 100%;
  padding: 6vw 6vw 20vw;
  
  @media all and (max-width: 768px) {
      width: 80%;
      padding: 6vw 6vw 30vw;
  }
}

.col__content--1 {
  background: var(--wasabi-light-1);
  
  @media all and (max-width: 768px) {
      background: rgba(var(--wasabi-light-1-rgb), 0.9);
  }
}

.col__content--2 {
  background: var(--wasabi-light-2);
  
  @media all and (max-width: 768px) {
      background: rgba(var(--wasabi-light-2-rgb), 0.9);
  }
}

.col__content--3 {
  background: var(--wasabi-medium);
  
  @media all and (max-width: 768px) {
      background: rgba(var(--wasabi-medium-rgb), 0.9);
  }
}

.col__content--4 {
  background: var(--wasabi-light-1);
  
  @media all and (max-width: 768px) {
      background: rgba(var(--wasabi-light-1-rgb), 0.9);
  }
}

.col__content--5 {
  background: var(--wasabi-light-2);
  
  @media all and (max-width: 768px) {
      background: rgba(var(--wasabi-light-2-rgb), 0.9);
  }
}

.col__content--6 {
  background: var(--wasabi-dark-1);
  
  @media all and (max-width: 768px) {
      background: rgba(var(--wasabi-dark-1-rgb), 0.9);
  }
}

.col__content-title {
  margin: 0 0 2vw;
  font-size: 7vw;
  letter-spacing: -0.8vw;
  
  @media all and (max-width: 768px) {
      margin: 0 0 6vw;
      font-size: 13vw;
  }
  @media all and (max-width: 400px) {
    margin: 0 0 6vw;
    font-size: 12vw;
  }
}

.col__content-wrap {
  display: flex;
  justify-content: flex-end;
  
  @media all and (max-width: 768px) {
      flex-direction: column;
  }
}

.col__content-txt {
  max-width: 22vw;
  order: 2;
  margin-left: 32px;
  
  @media all and (max-width: 768px) {
      order: 1;
      max-width: 60vw;
      margin: 0 0 10vw 10vw;
  }

  @media all and (max-width: 400px) {
    margin: 0 0 10vw 2vw;
  }
}

.slide-link {
  position: relative;
  order: 1;
  display: flex;
  justify-content: flex-end;
  width: 75px;
  height: 53px;
  
  > * {
      pointer-events: none;
  }
  
  @media all and (max-width: 768px) {
      order: 2;
      align-self: flex-end;
  }
}

.slide-link__circ {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  border: 1px solid var(--dark);
}

.slide-link__line {
  position: absolute;
  top: 25px;
  left: 0;
  width: 64px;
  height: 3px;
  background: var(--dark);
}

.line {
  overflow: hidden;
  
  &:nth-of-type(even) {
      margin-top: -1vw;
  }
}

.line__inner {
  display: block;
}

.slide__scroll-link {
  position: absolute;
  right: -113px;
  bottom: 3.5vw;
  display: block;
  width: 140px;
  height: 140px;
  background: var(--dark);
  overflow: hidden;
  
  @media all and (max-width: 768px) {
      display: none;
  }
}

.slide__scroll-line {
  position: absolute;
  left: 26px;
  bottom: 0;
  width: 1px;
  height: 100%;
}

.col__image-wrap {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 160vh;
}

.slide--0 {
  .slide__scroll-line {
      background: #C0D7D8;
  }
}

.slide--1 {
  .slide__scroll-line {
      background: #D8C0C0;
  }
}

.slide--2 {
  .slide__scroll-line {
      background: #CDD5E0;
  }
}

.slide--3 {
  .slide__scroll-line {
      background: #F3D3B0;
  }
}

.slide--4 {
  .slide__scroll-line {
      background: #F8E9E6;
  }
}

.slide--5 {
  .slide__scroll-line {
      background: #D1E2EC;
  }
}

.slide--6 {
  .slide__scroll-line {
      background: #D7CEC5;
  }
}



.img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--wasabi-accent-1);
}

.footer__link {
  font-size: 3vw;
  color: white;
  text-decoration: none;
  font-family: 'Cinzel', serif;
}

.footer__link-top {
  position: absolute;
  left: 50%;
  bottom: 100px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background: var(--dark);
  font-size: 18px;
  color: white;
  text-decoration: none;
  font-family: 'Cinzel', serif;
}

.footer__link-top-line {
  position: absolute;
  top: -50px;
  left: 50%;
  width: 1px;
  height: 30px;
  background: var(--dark);
}

.footer__copyright {
  position: absolute;
  left: 50%;
  bottom: 24px;
  transform: translateX(-50%);
}

.height--400 {
  height: 400px;
}

.height--600 {
  height: 600px;
}

.height--800 {
  height: 800px;
}

.height--1000 {
  height: 1000px;
}

.height--1200 {
  height: 1200px;
}

.cta {
  background: var(--wasabi-accent-2);
  color: white;
}



/* CSS */
.buttonCta {
  appearance: none;
  background-color: var(--wasabi-accent-2);
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}

.buttonCta:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.buttonCta:hover {
  background-color: #2c974b;
}

.buttonCta:focus {
  box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
  outline: none;
}

.buttonCta:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.buttonCta:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}